<template>
  <div :class="isImpersonated ? 'impersonate': ''">
    <h4 v-if="isImpersonated && user" class="impersonate__text">IMPERSONATING {{ user.name }}</h4>
    <b-navbar toggleable sticky type="dark" class="dab-navbar" :class="user && auth.isAuthenticated() ? 'pr-1' : 'pr-3'">
      <!-- Region -->
      <div class="position-absolute w-100 region-button-wrapper">
        <b-button class="mx-1 region-button border border-white" title="Region" size="sm" :class="{ 'border-bottom': regionId == null }" @click="changeRegion">
          <span v-if="region" class="hidden-sm-down">{{ region.name }}</span>
          <span class="mdi mdi-map-marker-radius-outline"></span>
        </b-button>
      </div>

      <b-navbar-brand v-if="vendor" to="/" class="nav-brand">
        <b-img v-if="width >= 768" :src="vendor.settings.desktop_logo_url" />
        <b-img v-else-if="vendor.settings.mobile_logo_url" :src="vendor.settings.mobile_logo_url" />
      </b-navbar-brand>

      <b-navbar-nav class="ml-auto">
        <b-nav-form>
          <!-- Cart -->
          <b-button class="pl-2 pr-1 bg-transparent border-0" title="Cart" pill :class="{ 'border-bottom': $router.currentRoute.path === '/cart' }" @click="$router.push('/cart').catch(e => {})">
            <span class="mdi mdi-cart cart-icon"></span>
            <transition name="fade">
              <span v-if="cartProducts.length > 0" class="cart-badge badge badge-danger">{{ cartProducts.length }}</span>
            </transition>
          </b-button>
          <b-button v-b-toggle.sidebar class="ml-1 bg-transparent border-0">
            <b-icon icon="list" color="white"></b-icon>
          </b-button>
        </b-nav-form>
      </b-navbar-nav>
    </b-navbar>
    <b-container ref="container" class="app-container noselect elevation-10">
      <div v-if="showScrollIndicator && [routeMap.cart.name, routeMap.checkout.name].includes($route.name)" class="scroll-indicator w-100">
        <b-icon icon="arrow-up-short" animation="throb" class="scroll-icon" font-scale="2" @click="window.scrollTo(0, 0)"></b-icon>
      </div>

      <b-sidebar
        v-if="region"
        id="sidebar"
        v-model="sidebar"
        right
        width="100vw"
        bg-variant="dark"
        text-variant="light"
        shadow="lg"
        :title="user && auth.isAuthenticated() ? `Hi, ${user.name}` : region ? region.name : `Menu`">
        <div class="sidebar-container">
          <nav>
            <b-nav vertical class="sidebar-nav">
              <b-nav-item to="/" :class="{ active: $router.currentRoute.path === '/' }">
                <span class="mdi mdi-storefront mr-3 primary"></span>
                <span>Shop</span>
              </b-nav-item>
              <b-nav-item
                v-if="user && auth.isAuthenticated()"
                :class="{
                  active: $router.currentRoute.path === routeMap.orderHistory.path
                }"
                @click="$router.push(routeMap.orderHistory.path).catch(e => {})">
                <span class="mdi mdi-history mr-3 primary"></span>
                <span>My Order History</span>
              </b-nav-item>
              <b-nav-item
                v-if="vendor && vendor.settings && vendor.settings.enable_goal_center"
                :class="{
                  active: $router.currentRoute.path === routeMap.macronutrients.path
                }"
                @click="
                  () => {
                    if (user && auth.isAuthenticated()) {
                      $router.push(routeMap.macronutrients.path).catch(e => {})
                    } else {
                      $router.push(routeMap.validate.path)
                    }
                  }
                ">
                <span class="mr-3 pt-1 float-left">
                  <icon-base size="24" :icon-color="vendor.settings.theme_color_1 || '#38A7DA'">
                    <macros-icon></macros-icon>
                  </icon-base>
                </span>
                <span>Goal Center</span>
              </b-nav-item>

              <b-nav-item
                v-if="user && auth.isAuthenticated() && vendor && vendor.settings.enable_gift_cards"
                :class="{
                  active: $router.currentRoute.path === routeMap.giftCards.path
                }"
                @click="goToGiftCards()">
                <span class="mr-3 pt-1 float-left">
                  <icon-base size="24" :icon-color="vendor.settings.theme_color_1 || '#38A7DA'">
                    <GiftCardIcon />
                  </icon-base>
                </span>
                <span>Gift cards</span>
              </b-nav-item>

              <b-nav-item
                v-if="user && auth.isAuthenticated()"
                :class="{
                  active: $router.currentRoute.path === routeMap.subscriptions.path
                }"
                @click="$router.push(routeMap.subscriptions.path).catch(e => {})">
                <span class="mdi mdi-package-variant-closed mr-3 primary"></span>
                <span>My Subscriptions</span>
              </b-nav-item>
              <b-nav-item to="/map" :class="{ active: $router.currentRoute.path === '/map' }" :disabled="!regionId">
                <span class="mdi mdi-map-marker-radius-outline mr-3 primary"></span>
                <span v-if="region">{{ region.name }}</span>
                <span v-else>Delivery Hours & Area</span>
              </b-nav-item>
              <b-nav-item
                v-if="vendor && user && auth.isAuthenticated()"
                :class="{
                  active: $router.currentRoute.path === routeMap.userSettings.path
                }"
                @click="
                  () => {
                    if (user && auth.isAuthenticated()) {
                      $router.push(routeMap.userSettings.path).catch(e => {})
                    } else {
                      $router.push(routeMap.validate.path)
                    }
                  }
                ">
                <span class="mr-3 pt-1 float-left">
                  <icon-base size="24" :icon-color="vendor.settings.theme_color_1 || '#38A7DA'">
                    <gear-icon />
                  </icon-base>
                </span>
                <span>Settings</span>
              </b-nav-item>
              <b-nav-item v-if="user && auth.isAuthenticated()" @click="onShowLogoutModal">
                <span class="mdi mdi-logout mr-3 primary"></span>
                <span>Logout</span>
              </b-nav-item>
              <template v-else>
                <b-nav-item to="/register">
                  <span class="mdi mdi-form-select mr-3 primary"></span>
                  <span>Register</span>
                </b-nav-item>
                <b-nav-item to="/login">
                  <span class="mdi mdi-login mr-3 primary"></span>
                  <span>Login</span>
                </b-nav-item>
              </template>
              <b-modal v-model="showLogoutModal" hideFooter centered title="Are you sure you want to log out?">
                <div v-if="cartProducts.length > 0" class="logout-modal-alert">You have {{ cartProducts.length }} products in your cart. Are you sure?</div>
                <b-button variant="primary" class="logout-modal-button  btn-secondary" @click="onCloseLogoutModal">Cancel</b-button>
                <b-button variant="outline-danger" class="logout-modal-button" @click="logout">Logout</b-button>
              </b-modal>

              <template v-if="configuration.externalLinks.length > 0">
                <span class="ml-3 mt-3 mb-0 menu-label">Links</span>
                <b-dd-divider></b-dd-divider>
                <template v-for="x in Object.entries(configuration.externalLinks)">
                  <span v-if="x[0] === 'Break'" :key="x" class="ml-3 mt-3 mb-0 menu-label">External Links</span>
                  <b-dd-divider v-if="x[0] === 'Break'" :key="x[0]"></b-dd-divider>
                  <b-nav-item v-else :key="x[0]" :href="x[1].link" target="_blank">
                    <span :class="`mdi ${x[1].icon} mr-3 primary`"></span>
                    <span>{{ x[0] }}</span>
                  </b-nav-item>
                </template>
              </template>
            </b-nav>
          </nav>
          <div v-if="$store.getters.vendor && getCurrentPlatform.platform === 'web'" class="app-store-container py-2">
            <a v-if="$store.getters.vendor.settings.ios_app_url" :href="$store.getters.vendor.settings.ios_app_url" class="pr-2 pointer" target="_blank">
              <b-img :src="appStoreImg" alt="Download on the App Store" />
            </a>

            <a v-if="$store.getters.vendor.settings.android_app_url" :href="$store.getters.vendor.settings.android_app_url" target="_blank" class="pointer">
              <b-img :src="playStoreImg" alt="Get it on Google Play" />
            </a>
          </div>
          <div class="version-container">
            <div>
              © {{ new Date().getFullYear() }} -
              {{ $store.getters.vendor.settings.copyright_text }}
            </div>
            <div class="spacer"></div>
            <div>{{ appVersion }}</div>
          </div>
        </div>
      </b-sidebar>

      <b-alert v-if="$route.name !== 'home'" :show="alert.show" :variant="alert.variant" class="alert-toast mb-5" dismissible fade @dismissed="hideAlert">
        {{ alert.text }}
      </b-alert>

      <template v-if="vendor && vendor.settings.redirect_mobile_users_to_web && isNative">
        <b-container>
          <b-row align-h="center" class="text-center">
            <b-img class="py-5 vendor-construction-logo" v-if="vendor.logo_image" :alt="vendor.name" :src="vendor.logo_image" :title="vendor.name" lazy @error="vendor.logo_image = null" width="200"/>
            <p>The <b>{{ vendor.name }}</b> mobile application is currently under construction. 
              <br>Please order with the mobile website. Thanks!</p>
            <p><u>Tap the button</u> below to proceed to the mobile website:</p>
          </b-row>
          <b-row align-h="center" class="mt-2">
            <b-button class="btn-cta" :href="vendor.domain">Website <span class="mdi mdi-link-variant primary"></span></b-button>
          </b-row>
        </b-container>
      </template>
      <template v-else-if="vendor && !vendor.is_active_payment">
        <b-row align-h="center" class="text-center">
          <b-img class="py-5 vendor-construction-logo" v-if="vendor.logo_image" :alt="vendor.name" :src="vendor.logo_image" :title="vendor.name" lazy @error="vendor.logo_image = null" width="200"/>
        </b-row>
        <b-row align-h="center" class="text-center">
          <p>This {{ isNative ? "application":"website" }} will become available as soon as <b>{{ vendor.name }}</b> connects their payment processor to the <a href="https://orderprepped.com/">Prepped Platform</a>. 
            <br>Please check back soon. Thanks!</p>
        </b-row>
      </template>
      <template v-else-if="doneDetecting">
        <transition duration="400" mode="out-in" name="fade">
          <template v-if="region">
            <router-view v-cloak class="router-view"></router-view>
          </template>
          <template v-else>
            <Regions :region-options="regionOptions"></Regions>
          </template>
        </transition>
      </template>
      <template v-else>
        <b-row align-h="center">
          <b-col class="mx-auto">
            <b-img :src="loadingGif" class="w-100" fluid></b-img>
          </b-col>
        </b-row>
      </template>
      <minimum-age-modal />
    </b-container>
      <b-modal  size="sm" :visible="Boolean(getCurrentPlatform.marketUrl.length)" hide-footer centered hide-header @hide="onModalClose">
        <div class="d-flex flex-column text-center align-items-center gap">
          <h3>Check out our app!</h3>
          <b-img v-if="$store.getters.vendor" :src="$store.getters.vendor.settings.mobile_logo_url" class="w-25 mb-2" alt="App Logo"/>
          <p>This Website has an mobile application version for your device</p>
          <a :href="getCurrentPlatform.marketUrl" class="pointer" target="_blank">
              <b-img :src="playStoreImg" alt="Get it on Google Play" v-if="getCurrentPlatform.operatingSystem==='android'"/>
              <b-img :src="appStoreImg" alt="Download on the App Store" v-if="getCurrentPlatform.operatingSystem==='ios'"/>
            </a>
        </div>
      </b-modal>
    <sticky-footer />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { routeMap } from '~/routes'
import { configuration } from '~/configuration'
import { auth } from '^/auth'
import { axios } from '^/axios'
import Regions from '@/Regions.vue'
import MinimumAgeModal from '~/partials/components/MinimumAgeModal.vue'
import StickyFooter from '~/partials/components/StickyFooter.vue'
import loadingGif from '~/../assets/loading.gif'
import appStoreImg from '~/../assets/appstore.png'
import playStoreImg from '~/../assets/playstore.png'
import packageJson from '../package.json'
import { Device } from '@capacitor/device'
import { Geolocation } from '@capacitor/geolocation'
import '../node_modules/bootstrap/dist/css/bootstrap.css'
import '../node_modules/bootstrap-vue/dist/bootstrap-vue.css'
import { PushNotifications } from '@capacitor/push-notifications'
import { FCM } from '@capacitor-community/fcm'
import { Capacitor } from '@capacitor/core'
import MacrosIcon from '@/common/Icons/MacrosIcon.vue'
import GiftCardIcon from '@/common/Icons/GiftCardIcon.vue'
import GearIcon from '@/common/Icons/GearIcon.vue'
import IconBase from '@/common/Icons/IconBase.vue'
import Smartlook from 'smartlook-client'
import { v4 as uuidv4 } from 'uuid'

export default {
  data() {
    return {
      configuration,
      isImpersonated: false,
      geolocationOptions: {
        enableHighAccuracy: true,
        timeout: 1500,
        maximumAge: 3600000 // One hour
      },
      regionOptions: Array(),
      doneDetecting: false,
      sidebar: false,
      loadingGif,
      appStoreImg,
      playStoreImg,
      deviceInfo: null,
      auth,
      routeMap,
      window,
      showScrollIndicator: false,
      width: window.innerWidth,
      showLogoutModal: false
    }
  },
  computed: {
    ...mapGetters(['region', 'regions', 'regionId', 'cartProducts', 'alert', 'user', 'vendor']),
    isNative() {
      return Capacitor.isNativePlatform()
    },
    appVersion() {
      const envName = import.meta.env.VITE_VISIBLE_ENVIRONMENT_LABEL
      if (envName) {
        return `v${packageJson.version} (${envName})`
      }
      return `v${packageJson.version}`
    },
    personalization() {
      return JSON.parse(localStorage.getItem('personalization')) || {};
    },
    getCurrentPlatform() {
      const platformInfo = {
        platform: '',
        operatingSystem: '',
        marketUrl: ''
      }
      if(this.deviceInfo) {
        platformInfo.platform = this.deviceInfo.platform
        platformInfo.operatingSystem = this.deviceInfo.operatingSystem
      }
      if (platformInfo.platform == 'web' && this.isModalVisible) {
        if(platformInfo.operatingSystem === 'android' && this.vendor?.settings.android_app_url) {
        platformInfo.marketUrl = this.vendor.settings.android_app_url;
        } 
        if(platformInfo.operatingSystem === 'ios' && this.vendor?.settings.ios_app_url) {
          platformInfo.marketUrl = this.vendor.settings.ios_app_url;
        }
      }
      return platformInfo;
    },
    isModalVisible() {
      const WEEK_IN_MS = 7 * 24 * 60 * 60 * 1000;
      const lastShown = localStorage.getItem('popupLastShown');
      const now = Date.now();
      if (!lastShown || now - lastShown > WEEK_IN_MS) {
        return true;
      }
      return false;
    },
    impersonation() {
        return sessionStorage.getItem('impersonation') === 'true';
    }
  },
  methods: {
    ...mapActions(['hideAlert', 'fetchUser']),
    async getRegions(position) {
      try {
        const regions = position
        ? await axios.get(`regions`, {
          params: {
            latitude: position.coords.latitude.toFixed(7),
            longitude: position.coords.longitude.toFixed(7),
            vendor_uuid: this.vendor.uuid
          }
        })
        : await axios.get(`regions`, {
          params: {
            vendor_uuid: this.vendor.uuid
          }
        })
        
        this.regionOptions = regions.data.map(region => ({
        value: region?.id,
        text: region?.name
      }))
      
      if (regions.active) {
        this.$store.commit('regionId', regions.active.id)
      }
      
      this.$store.commit('regions', regions.data)

      return Promise.resolve(regions)
    } catch {
      return Promise.reject(new Error('Unable to fetch regions'))
    } finally {
      this.doneDetecting = true
    }
    },
    changeRegion() {
      this.$store.commit('clearCart')
      this.$store.commit('regionId', null)
    },
    logout() {
      this.$store.commit('user', null);
      this.$store.commit('clearCart');  
      auth.destroyToken();
      this.isImpersonated = false;
      this.sidebar = false;
      this.$router.push('/').catch(() => { });
      this.onCloseLogoutModal()
    },
    onModalClose() {
      localStorage.setItem('popupLastShown', Date.now());
    },

    goToGiftCards() {
      if (this.$route.name === 'gift-cards') return

      if (this.user && auth.isAuthenticated()) {
        this.$router.push(routeMap.giftCards.path)
      } else {
        this.$router.push(routeMap.validate.path)
      }
    },

    onShowLogoutModal() {
      this.sidebar = false
      this.showLogoutModal = true;
    },

    onCloseLogoutModal() {
      this.showLogoutModal = false;
    },

    async setUpRegions() {
      if (!this.vendor?.uuid) return

      try {
        const coordinates = await Geolocation.getCurrentPosition(this.geolocationOptions)

        if (coordinates && coordinates.coords) {
          await this.getRegions(coordinates)
        } else {
          await this.getRegions()
        }
      } catch (e) {
        await this.getRegions()
      }
    }
  },
  async created() {
    Smartlook.identify(uuidv4(), {
      advancedNetwork: {
        allowedUrls: [/^https:\/\/orderprepped\.com\/.*/]
      }
    })

    if (Capacitor.isNativePlatform()) {
      let fcmToken = null
      FCM.getToken().then(r => {
        localStorage.setItem('fcmToken', r.token)
      })
    }

    window.onresize = () => {
      this.width = window.innerWidth
    }
  },

  async mounted() {
    this.setUpRegions()
    this.deviceInfo = await Device.getInfo();

    if (Capacitor.isNativePlatform()) {
      let permStatus = await PushNotifications.checkPermissions()

      permStatus = await PushNotifications.requestPermissions()
      if (permStatus.receive === 'prompt') {
        permStatus = await PushNotifications.requestPermissions()
      }
      if (permStatus.receive !== 'granted') {
        throw new Error('User denied permissions!')
      }
      await PushNotifications.register()
      await PushNotifications.getDeliveredNotifications()
    }

    if (auth.isAuthenticated()) {
      this.$store.dispatch('getGiftCardsInfo');
    }
    this.isImpersonated = this.impersonation;
    await this.$router.onReady(async () => {
      if(this.$route.query.token) {
        // For Log in as impersonated user
        sessionStorage.setItem('impersonation', true);
        auth.setBearerJwt(this.$route.query.token);
        auth.setGoogleAutocompleteToken(this.$uuid.v4());
        auth.setExpiry(this.$route.query.expiry);
        document.title = (this.vendor) ? this.vendor.name : 'Prepped | Meal Prep Software - No Upfront Costs.';
        const user = await this.fetchUser();
        const impersonateData = await axios.get(`/impersonate/${user.uuid}/data`)
        auth.setExtendedRoleClaims(impersonateData.extended_claims);
        this.isImpersonated = true;
        await this.$store.dispatch('getGiftCardsInfo');
        await this.$router.replace({name: 'home', query: {}})
      }
    });
  },

  watch: {
    'vendor.uuid'() {
      this.setUpRegions()
    }
  },

  components: {
    Regions,
    MinimumAgeModal,
    StickyFooter,
    MacrosIcon,
    GiftCardIcon,
    GearIcon,
    IconBase
  }
}
</script>
<style lang="scss">
:root {
  --gray: #17a2b8;
  --gray-dark: #17a2b8;
}

$theme-colors: ();

@import './styles/variables.module.scss';

.modal-dialog {
  padding-top: env(safe-area-inset-top);
}

.scroll-indicator {
  padding-top: env(safe-area-inset-top);
  position: sticky;
  position: -webkit-sticky;
  top: 70px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #000;
  z-index: 1000;

  .scroll-icon {
    margin-right: 20px;
    border-radius: 50%;
    background-color: var(--primary);
    color: white;

    &:hover {
      background-color: black;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

// ::-webkit-scrollbar {
//   display: none;
// }

html {
  height: 100%;
}

body {
  height: 100%;
  overscroll-behavior-y: none;
  overscroll-behavior-x: none;

  touch-action: manipulation;
  -webkit-font-smoothing: antialiased;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .btn {
    font-family: $staatliches-font;
  }

  p {
    font-size: 125%;
  }
}

.col-form-label,
.form-control {
  font-size: unset;
  height: unset;
  line-height: unset;
}

::placeholder {
  color: $placeholder-font-color;
  opacity: 1;
}

:-ms-input-placeholder {
  color: $placeholder-font-color;
}

::-ms-input-placeholder {
  color: $placeholder-font-color;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.alert-toast {
  top: 2rem;
  margin-left: 2rem;
  margin-right: 2rem;
}

.display-4 {
  letter-spacing: 0.035em !important;
  font-weight: 900 !important;
}

.btn.btn-cta {
  font-size: 2rem !important;
}

.router-view {
  overflow-x: visible;
}

.vs__dropdown-menu > li {
  padding: 0.5em 0.2em !important;
}

#sidebar header {
  margin-top: env(safe-area-inset-top);
  padding-left: 0 !important;
}

.b-sidebar > .b-sidebar-header .close {
  font-size: 2.1em !important;
}

.b-sidebar > .b-sidebar-header {
  background-color: var(--secondary);
}

.b-sidebar .dropdown-divider {
  border-color: lightgray !important;
  color: lightgray !important;
}
</style>
<style lang="scss" scoped>
@import './styles/variables.module.scss';
@include buttons;

.app-container {
  height: 100%;
  overflow: auto;
  overscroll-behavior-y: none;
  overscroll-behavior-x: none;
  background-color: $content-bg-color;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 15px;
  height: 100%;
  overflow-x: hidden;

  .navbar {
    zoom: 1.2;
    -moz-transform-origin: 0 0;
  }
}

.dab-navbar {
  font-family: $staatliches-font;
  background-color: var(--secondary);
  color: white;
  padding-top: calc(8px + env(safe-area-inset-top));

  .nav-brand {
    margin: 0;
    padding: 0;

    img {
      max-height: 40px !important;
    }
  }

  .site-title {
    font-family: $brand-font-style;

    &:hover {
      cursor: pointer;
    }
  }

  a.nav-link {
    color: $content-bg-color;
  }

  .menu {
    border: none;
  }

  .footer {
    font-size: 0.85em;
  }
}

.region-button-wrapper {
  left: 0;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
}

.region-button {
  background-color: var(--primary);
  font-weight: bolder;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.26);
  letter-spacing: 0.035em;
  border-radius: 50px;
  border-width: 2px !important;
  display: flex;
  align-items: center;
}

#sidebar {
  .sidebar-container {
    display: flex;
    flex-direction: column;
    min-height: 100%;

    nav {
      flex: 1;

      .sidebar-nav {
        .menu-label {
          color: lightgray;
        }

        .mdi.primary {
          color: var(--primary);
        }

        li a {
          color: white;
          font-family: $staatliches-font;
          font-size: 1.6em;

          &:hover {
            background-color: black;
          }
        }

        li.active a {
          background-color: black;
        }
      }
    }

    .app-store-container {
      display: flex;
      align-items: center;
      margin: 1rem;

      @media (max-width: 720px) {
        justify-content: center;
      }

      a {
        max-width: 50%;

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .version-container {
      display: flex;
      font-size: 0.75rem;
      margin: 0.5rem 1rem;

      .spacer {
        flex: 1;
      }
    }
  }
}

.gap {
  gap: 16px;
}

.logout-modal-button {
  width: 100%;
  margin-top: 12px;
}

.logout-modal-alert {
  font-size: 20px;
  font-weight: 500;
}

.impersonate {
  border: 4px solid red;
  box-sizing: border-box;
  &__text {
    position: fixed;
    color: red;
    z-index: 5000;
    text-align: center;
    width: 100%;
    letter-spacing: 2px;
    pointer-events:none;
  }
}

.vendor-construction-logo {
  border-radius: 50%;
}
</style>
